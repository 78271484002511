@import "../../styles/variables.scss";

.container {
    background: radial-gradient(100% 100% at 0% 0%, rgba(112, 205, 231, 0.3) 0%, rgba(112, 205, 231, 0) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(108.63% 108.53% at 0% 0%, rgba(74, 55, 255, 0.5) 0%, rgba(74, 55, 255, 0) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    color: $white;
}
