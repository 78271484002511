@import "../../styles//variables.scss";

.section {
    min-height: 600px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    // background: "radial-gradient(circle at 80% 32%, rgba(37,32,96,1) 13%, rgba(24,24,36,1) 35%)",
    color: $white;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 2%;
    padding-bottom: 2%;
}
