$blue: #4a37ff;
$cyan: #70cde7;
$darkblue: #181824;
$white: #ffffff;

:export {
    blue: $blue;
    cyan: $cyan;
    darkblue: $darkblue;
    white: $white;
}
