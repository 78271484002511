.container {
    margin-top: 50px;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    width: 100px;
    height: 100px;
    position: absolute;
    background: linear-gradient(197deg, rgba(112, 205, 231, 0.18) -17.4%, rgba(112, 205, 231, 0) 96.77%),
        radial-gradient(57.18% 106.69% at 50% 50%, rgba(74, 55, 255, 0.04) 0%, rgba(74, 55, 255, 0.164) 100%), #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(73px);
    position: absolute;
    bottom: 50%;
    z-index: 100;
}

.content {
    height: 100px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 15px;
    border-top: 1px solid rgb(105, 100, 171);
    border-right: 1px solid rgb(105, 100, 171);
    border-radius: 8px;
    background-color: unset;
    background: linear-gradient(211.03deg, rgba(112, 205, 231, 0.18) 14.83%, rgba(142, 125, 163, 0) 91.8%),
        radial-gradient(57.18% 106.69% at 50% 50%, rgba(74, 55, 255, 0.1) 0%, rgba(74, 55, 255, 0.41) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: inset -1.45px 1.45px 0px rgba(215, 181, 249, 0.37);
    backdrop-filter: blur(18.3212px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px;
}
