@import "../../styles/variables.scss";

.container {
    min-height: unset;
    box-sizing: border-box;
    background-color: $darkblue;
    margin: unset;
    align-items: flex-start;
    padding: 5%;
}

.socialIcon {
    border: 1px solid $white;
    border-radius: 100%;
    padding: 5px;
    margin: 1%;
}

.linkList {

    li,
    a {
        padding-left: 0;
        opacity: 50%;
    }
}