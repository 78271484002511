@import "../../styles/variables.scss";

.blockchainFloatingImageContainer {
    position: absolute;
    top: 42%;
    right: 20%;
    scale: 0.7;
    opacity: 0.3;
    z-index: 1;
}

.blockchainFloatingImage {
    background: radial-gradient(37.87% 37.87% at 19.69% 27.16%, rgba(200, 181, 255, 0.29) 0%, rgba(51, 37, 88, 0) 100%),
        radial-gradient(82.88% 82.88% at 21.49% 17.12%, $blue 0%, rgba(74, 55, 255, 0.54) 100%);
    width: 150px;
    height: 150px;
    box-shadow: inset 1.45372px 0px 0px rgb(160 143 200 / 45%);
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    transition: all 0.4s ease-in-out;

    &.delay1 {
        animation: float 4s ease-in-out infinite;
    }

    &.delay2 {
        animation: float 4.5s ease-in-out infinite;
    }

    &.delay3 {
        animation: float 5s ease-in-out infinite;
    }

    &.floatingImageSmall {
        height: 70px;
        width: 70px;
    }
}

// START Fade Transform Effects
.positionRelative {
    position: relative;
}

.fadeInRight {
    opacity: 1;
    animation-name: fadeInRightOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
}

.fadeOutLeft {
    opacity: 0;
    animation-name: fadeOutLeftOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
}

.fadeInLeft {
    opacity: 1;
    animation-name: fadeInLeftOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
}

.fadeOutRight {
    opacity: 0;
    animation-name: fadeOutRightOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
}

// END Fade Transform Effects

.slideRight {
    animation: slideRight 1s forwards;
    -webkit-animation: slideRight 1s forwards;
}

.slideLeft {
    animation: slideLeft 1s forwards;
    -webkit-animation: slideLeft 1s forwards;
}

.textSlider {
    :global(.slick-list) {
        :global(.slick-track) {
            height: unset;
        }
    }
}

.centerPadding {
    :global(.slick-center) {
        margin: 0 15px;
    }
}

.whatWeDoSlider {
    height: 300px;

    :global(.slick-track) {
        height: 300px;
        margin: auto;
    }

    :global(.slick-slide):not(:global(.slick-center)) {
        transform: scale(0.8);
    }

    :global(.slick-slide):first-child,
    :global(.slick-slide):last-child {
        transform: scale(0.6);
    }

    :global(.slick-center) {
        transform: scale(1);
    }

    :global(.slick-slide) {
        display: flex;
        align-items: center;
    }
}

.headerWiseLogo {
    position: absolute;
    width: 35%;
    margin-top: -30%;
    z-index: 2;
    animation: logoFloat 4s ease-in-out infinite;
}

.headerBanners1 {
    position: absolute;
    width: 70%;
    margin-top: 10%;
    z-index: 3;
    animation: bannerFloat 4s ease-in-out infinite;
}

.headerBanners2 {
    position: absolute;
    width: 70%;
    margin-top: -35%;
    z-index: 1;
    animation: bannerFloat 4s ease-in-out infinite;
}

.headerBasePlatform {
    position: absolute;
    width: 170%;
    margin-top: 65%;
    transition: all 0.4s ease-in-out;
}

.headerImageContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative;
    min-height: 350px;
    width: 100%;

    &.small {
        padding: 60% 50%;
        box-sizing: border-box;
    }
}

.headerCoins {
    width: 12%;
    position: absolute;
    animation: float 4s ease-in-out infinite;
}

.bcLogo {
    margin-top: -75%;
    margin-right: 56%;
}

.ethLogo {
    margin-top: -60%;
    margin-left: 55%;
}

@keyframes logoFloat {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(10px);
    }

    100% {
        transform: translatey(0px);
    }
}

@keyframes bannerFloat {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-10px);
    }

    100% {
        transform: translatey(0px);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-400%);
    }

    50% {
        transform: translateX(410%);
    }

    100% {
        transform: translateX(400%);
    }
}

@-webkit-keyframes slideRight {
    0% {
        transform: translateX(-400%);
    }

    50% {
        transform: translateX(410%);
    }

    100% {
        -webkit-transform: translateX(400%);
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(400%);
    }

    50% {
        transform: translateX(-410%);
    }

    100% {
        transform: translateX(-400%);
    }
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: translateX(400%);
    }

    50% {
        transform: translateX(-410%);
    }

    100% {
        -webkit-transform: translateX(-400%);
    }
}

.textSlider {
    :global(.slick-list) {
        :global(.slick-track) {
            height: unset;
        }
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

@keyframes fadeInRightOpacity {
    0% {
        opacity: 0;
        left: -20%;
    }

    100% {
        opacity: 1;
        left: 0%;
    }
}

@keyframes fadeOutLeftOpacity {
    0% {
        opacity: 1;
        left: 0%;
    }

    100% {
        opacity: 0;
        left: -20%;
    }
}

@keyframes fadeInLeftOpacity {
    0% {
        opacity: 0;
        right: -20%;
    }

    100% {
        opacity: 1;
        right: 0%;
    }
}

@keyframes fadeOutRightOpacity {
    0% {
        opacity: 1;
        right: 0%;
    }

    100% {
        opacity: 0;
        right: -20%;
    }
}
