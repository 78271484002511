.container {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;

    background: linear-gradient(211.03deg, rgba(112, 205, 231, 0.18) 14.83%, rgba(142, 125, 163, 0) 91.8%),
        radial-gradient(57.18% 106.69% at 50% 50%, rgba(74, 55, 255, 0.1) 0%, rgba(74, 55, 255, 0.41) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: inset -1.45px 1.45px 0px rgba(215, 181, 249, 0.37);
    backdrop-filter: blur(18.3212px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    width: 200px;
    height: 200px;

    position: relative;
}

.image {
    margin: 10px;
    width: 100%;
}

.title {
    position: absolute;
    padding: 10px;
    bottom: 25px;
}
