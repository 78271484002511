.slick-list, .slick-slider, .slick-track
  position: relative
  display: block

.slick-loading
  .slick-slide, .slick-track
    visibility: hidden

.slick-slider
  box-sizing: border-box
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-touch-callout: none
  -khtml-user-select: none
  -ms-touch-action: pan-y
  touch-action: pan-y
  -webkit-tap-highlight-color: transparent

.slick-list
  overflow: hidden
  margin: 0
  padding: 0

  &:focus
    outline: 0

  &.dragging
    cursor: pointer
    cursor: hand

.slick-slider
  .slick-list, .slick-track
    -webkit-transform: translate3d(0, 0, 0)
    -moz-transform: translate3d(0, 0, 0)
    -ms-transform: translate3d(0, 0, 0)
    -o-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slick-track
  top: 0
  left: 0

  &:after, &:before
    display: table
    content: ''

  &:after
    clear: both

.slick-slide
  display: none
  float: left
  height: 100%
  min-height: 1px

[dir=rtl] .slick-slide
  float: right

.slick-slide
  img
    display: block

  &.slick-loading img
    display: none

  &.dragging img
    pointer-events: none

.slick-initialized .slick-slide
  display: block

.slick-vertical .slick-slide
  display: block
  height: auto
  border: 1px solid transparent

.slick-arrow.slick-hidden
  display: none

/*# sourceMappingURL=slick.min.css.map
    