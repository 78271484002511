@import "../../styles/variables.scss";

.container {
    background-position: right;
    background-repeat: no-repeat;
    background-color: $blue;
}

.formContainer {
    input,
    label,
    :global(.Mui-focused) {
        color: $white;
    }

    fieldset,
    :global(.Mui-OutlinedInput-notchedOutline) {
        border-color: $white;
    }
}
